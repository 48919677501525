import { parseColor } from '@progress/kendo-drawing';

// Linear color scale from the given color to white minus minimum lightness offset.
const colorScale = (color, minLightnessOffset = 0.05) => {
    const baseColor = parseColor(color);
    const offset = 1 - minLightnessOffset;

    return (value) => {
        const hsl = baseColor .toHSL();
        const range = 100 - hsl.l;
        const point = offset - value;
        hsl.l += Math.min(point * range, range);

        return hsl.toCss();
    };
};

export default colorScale;
