export const ARC = "arc";
export const AXIS_LABEL_CLICK = "axisLabelClick";
export const BLACK = "#000";
export const BOTTOM = "bottom";
export const CENTER = "center";
export const CIRCLE = "circle";
export const COORD_PRECISION = 3;
export const CROSS = "cross";
export const DATE = "date";
export const DEFAULT_FONT = "12px sans-serif";
export const DEFAULT_HEIGHT = 400;
export const DEFAULT_PRECISION = 10;
export const DEFAULT_WIDTH = 600;
export const END = "end";
export const FORMAT_REGEX = /\{\d+:?/;
export const HEIGHT = "height";
export const HIGHLIGHT_ZINDEX = 100;
export const INSIDE = "inside";
export const LEFT = "left";
export const MAX_VALUE = Number.MAX_VALUE;
export const MIN_VALUE = -Number.MAX_VALUE;
export const NONE = "none";
export const NOTE_CLICK = "noteClick";
export const NOTE_HOVER = "noteHover";
export const NOTE_LEAVE = "noteLeave";
export const OBJECT = "object";
export const OUTSIDE = "outside";
export const RIGHT = "right";
export const ROUNDED_RECT = "roundedRect";
export const START = "start";
export const STRING = "string";
export const TOP = "top";
export const TRIANGLE = "triangle";
export const VALUE = "value";
export const WHITE = "#fff";
export const WIDTH = "width";
export const X = "x";
export const Y = "y";
export const DEFAULT_SERIES_OPACITY = 1;
